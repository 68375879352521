import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import './index.css';
import App from './containers/App/App';
import * as serviceWorker from './serviceWorker';
import thunk from 'redux-thunk';
import generalReducer from './store/reducers/general';
import clientDataPlatformReducer from './store/reducers/clientDataPlatform';
import reducer from "./store/reducers/keywordExportTool.js"
import bulkUploadToolReducer from './store/reducers/bulkUploadTool';

const composeEnhancers = process.env.NODE_ENV === 'development' ?  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : null || compose;

const rootReducers = combineReducers({
    general: generalReducer,
    accuranker: reducer,
    clientDataPlatform: clientDataPlatformReducer,
    bulkUploadToolReducer: bulkUploadToolReducer
});

const store = createStore(rootReducers, composeEnhancers(applyMiddleware(thunk)));

const app = (
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));
serviceWorker.unregister();
