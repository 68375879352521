import React, { Component } from 'react';
import styles from './Layout.module.css';
import { connect } from 'react-redux';
import Topbar from '../Topbar/Topbar';
import LeftSideNav from '../LeftSideNav/LeftSideNav';
import {Container, Row, Col} from 'react-bootstrap';

class Layout extends Component {
  state = {
    
  }

  render() {
    return (
      <Container fluid>
        <Row>
          <Col className={styles.leftSideBar}>
            <LeftSideNav />
          </Col>
          <Col className={styles.mainContent}>
            <Topbar className={styles.topBar} />
            <div className={styles.mainContentBackground}>
              {this.props.children}
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default connect()(Layout);