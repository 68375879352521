import React from 'react';
import { Accordion } from 'react-bootstrap';
import styles from './SideNavAccordionItem.module.css';
import NavigationItem from '../../NavigationItem/NavigationItem';

const SideNavAccordionItem = (props) => {
  let navItems = props.navItems;

  return (
    <Accordion.Item className={styles.accordionItem} eventKey={`${props.eventKey}`}>
      <Accordion.Header className={styles.accordionHeader}>{`${props.header}`}</Accordion.Header>
      <Accordion.Body className={styles.accordionBody}>
        <ul className={styles.accordionList}>
          {navItems.map((navItemData, index) => (
            <NavigationItem 
              key={index}
              link={navItemData.link}
              icon={navItemData.icon}
              title={navItemData.title}
            />
          ))} 
        </ul>
      </Accordion.Body>
    </Accordion.Item>
  ) 
}

export default SideNavAccordionItem;