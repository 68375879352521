import React from 'react';
import styles from './LeftSideNav.module.css';
import Logo from '../Logo/Logo';
import SideNavAccordion from '../Navigation/SideNavAccordion/SideNavAccordion';
import Footer from '../Footer/Footer';

const LeftSideNav = () => (
  <nav className={styles.leftSideNav}>
    <Logo />
    <SideNavAccordion />    
    <Footer />
  </nav>
);

export default LeftSideNav;