import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
  rows: [],
  refData: {
    packages: [],
    verticals: [],
  },
};

const setCDPData = (state, action) => {
  let cdpData = action.result.data;

  let updatedState = updateObject(state, {
    rows: cdpData,
  });
  return updateObject(state, updatedState);
};

const storeRefData = (state, action) => {
  let updatedState = {
    refData: {
      packages: action.result.packagesData,
      verticals: action.result.verticalsData,
    }
  }
  return updateObject(state, updatedState);
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_CDP_DATA:
      return setCDPData(state, action);
    case actionTypes.STORE_REF_DATA:
      return storeRefData(state, action);
    default:
      return state;
  }
}

export default reducer;