import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    accurankerData: '',
    dataLoadComplete: false
};

const loadAccuRankerData = (state, action) => {
    return updateObject(state, {
        accurankerData: action.results,
        dataLoadComplete: true
    });
}

const donePushAccuRankerData = (state) => {
    return updateObject(state, {
        accurankerData: '',
        dataLoadComplete: false
    });
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.Get_AccuRanker_Data: return loadAccuRankerData(state, action);
        case actionTypes.Push_AccuRanker_Data: return donePushAccuRankerData(state);
        default: return state;
    }
}

export default reducer;
