// Returns a new copy of the object without modifying the first one
export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

// Simple Rules for checking valid input. Uses "rules" in the component
export const checkValidity = (value, rules) => {
    let isValid = true;

    // Checks to see if the rules are required to be checked.
    if(rules.required){
        isValid = value.trim() !== '' && isValid;
        if(!isValid) {
            return isValid;
        }
    }

    // This makes sure the input is above the minimum width set against the input
    if(rules.minLength) {
        isValid = value.length >= rules.minLength && isValid;
        if(!isValid) {
            return isValid;
        }
    }

    // This makes sure the input is above the maximum width set against the input
    if(rules.maxLength) {
        isValid = value.length <= rules.maxLength && isValid;
        if(!isValid) {
            return isValid;
        }
    }

    return isValid;
}