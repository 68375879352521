import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  uploadedFiles: [],
};

const setUploadResponseToStore = (state, action) => {
  const newUploadedFiles = [...state.uploadedFiles];
  newUploadedFiles[action.index] = action.results;
  return updateObject(state, {
    uploadedFiles: newUploadedFiles,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPLOAD_CSV_DATA:
      return setUploadResponseToStore(state, action);
    default:
      return state;
  }
};

export default reducer;
